
import {
  defineComponent, onBeforeMount, reactive,
} from 'vue';
import Icone from '@/core/components/UI/Icone.vue';
import ComunicacaoApi from '@/core/components/UI/ComunicacaoApi.vue';
import { useAppBase } from '@/core/composables/AppBase';
import { IDTOPedido } from '@/models/DTO/IDTOPedido';
import ServicoPedido from '@/servicos/ServicoPedido';
import { IPedidoParametrosConsulta } from '@/models/ParametrosRequisicao/Consulta/IPedidoParametrosConsulta';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import { IOptionString } from '@/core/models/AntDesign/IOption';
import { IDTOPedidoTotalizador } from '@/models/DTO/IDTOPedidoTotalizador';
import TotalizadorDecorado from '@/core/components/UI/TotalizadorDecorado.vue';
import { EStatusPedido } from '@/models/Enumeradores/EStatusPedido';
import ComboStatusPedido from '@/components/Pedidos/ComboStatusPedido.vue';

export default defineComponent({
  name: 'PedidoListagem',
  components: {
    Icone,
    ComunicacaoApi,
    TotalizadorDecorado,
    ComboStatusPedido,
  },
  setup() {
    const { appBase } = useAppBase();
    const servicoPedido = new ServicoPedido();

    const state = reactive({
      pedidos: [] as IDTOPedido[],
      totalizadores: [] as IDTOPedidoTotalizador[],
      parametrosConsulta: {} as IPedidoParametrosConsulta,
      apresentarFiltros: false,
      ordenarPor: 'CodigoPedidoDESC',
      opcoesOrdenacao: [] as IOptionString[],
    });
    let debounce = 0;

    state.opcoesOrdenacao = [];
    const ordemMaisRecentes:IOptionString = { value: 'CodigoPedidoDESC', label: 'Mais recentes' } as IOptionString;
    const ordemNomeAsc:IOptionString = { value: 'NomePedidoASC', label: 'Nome (A-Z)' } as IOptionString;
    const ordemNomeDesc:IOptionString = { value: 'NomePedidoDESC', label: 'Nome (Z-A)' } as IOptionString;
    state.opcoesOrdenacao.push(ordemMaisRecentes);
    state.opcoesOrdenacao.push(ordemNomeAsc);
    state.opcoesOrdenacao.push(ordemNomeDesc);

    async function buscarDados() {
      appBase.carregando = true;
      state.apresentarFiltros = false;
      state.pedidos = [];
      if (UtilitarioGeral.valorValido(state.ordenarPor)) {
        state.parametrosConsulta.ordenacao = [];
        state.parametrosConsulta.ordenacao.push(state.ordenarPor);
      }

      if (appBase.paginacao.paginaAtual > 0) { state.parametrosConsulta.numeroPagina = appBase.paginacao.paginaAtual; }
      if (appBase.paginacao.qtdeRegistrosPagina > 0) { state.parametrosConsulta.qtdeRegistrosPagina = appBase.paginacao.qtdeRegistrosPagina; }
      state.parametrosConsulta.qtdeRegistrosTotal = 100;
      const respostaConsulta = await servicoPedido.obterPedidos(state.parametrosConsulta);
      if (UtilitarioGeral.validaLista(respostaConsulta.dados)) {
        state.pedidos = respostaConsulta.dados;
        appBase.paginacao = respostaConsulta.paginacao;
      }

      appBase.carregando = false;
    }

    function buscarPedidoPorNome() {
      clearTimeout(debounce);
      debounce = setTimeout(async () => {
        await buscarDados();
      }, 600);
    }

    async function buscarPedidosPorStatus(status:EStatusPedido) {
      state.parametrosConsulta.status = status;
      await buscarDados();
    }

    async function limparFiltros() {
      state.parametrosConsulta.status = 0;
      await buscarDados();
    }
    onBeforeMount(async () => {
      await buscarDados();
      state.totalizadores = await servicoPedido.obterTotalizadores();
    });

    async function paginacaAlterada(current: number, pageSize: number) {
      appBase.paginacao.paginaAtual = current;
      appBase.paginacao.qtdeRegistrosPagina = pageSize;
      await buscarDados();
    }
    async function qtdeRegistrosPaginaAlterada(current: number, pageSize: number) {
      appBase.paginacao.paginaAtual = 1;
      appBase.paginacao.qtdeRegistrosPagina = pageSize;
      await buscarDados();
    }

    function obterDescricaoStatus(status:number):string {
      return servicoPedido.obterDescricaoStatus(status);
    }

    function obterCorStatus(status:number):string {
      return servicoPedido.obterCorStatus(status);
    }

    function obterTotal(status:number):number {
      if (UtilitarioGeral.validaLista(state.totalizadores)) {
        const totalizador = state.totalizadores.find((c) => c.status === status);
        if (totalizador !== undefined) {
          return totalizador.total;
        }
      }
      return 0;
    }
    return {
      appBase,
      state,
      EStatusPedido,
      UtilitarioMascara,
      buscarDados,
      limparFiltros,
      buscarPedidoPorNome,
      paginacaAlterada,
      qtdeRegistrosPaginaAlterada,
      obterDescricaoStatus,
      obterCorStatus,
      obterTotal,
      buscarPedidosPorStatus,
    };
  },
});
